import { createSlice } from "@reduxjs/toolkit";
import { modifyData } from "../../utils/dataPersonaFilter";
import { getAnalyticsPages, getPersonasData } from "../apiSlice";

const initialState = {
  allData: [],
  filteredData: [],
  personasData: {},
  categoriesData: {},
  worldData: {},
  countriesData: {},
  viewsVisitorsData: {},
  citiesData: {},
  totalSpendData: {},
  ageData: {},
  genderData: {},
  visitorsLineData: {},
  roomsData: {},
  pageUrlData: {},
  sourceData: {},
  mediumData: {},
  campaignData: {},
  termData: {},
  contentData: {},
  selectedPersonaFilterType: "Daily",
  isLoading: true,
  pmsToggleActivation: false,
  heatmap1Data: [],
  heatmap2Data: [],
  heatmap3Data: [],
  selectedFilterTypeForHeatmap: "Visitors",
  companyType: 1,
  keywordsData: {},
  longTailKeyWordsData: {},
  pagesAndKewords: { isLoading: true },
  pagesAndKewordsFilteredData: [],
  fetchFilterData: false,
  barGraphLoading: false
};

const personasDataSlice = createSlice({
  name: "PersonasData",
  initialState,
  reducers: {
    updateFilteredData: (state, actions) => {
      return { ...state, filteredData: [...actions.payload] };
    },
    updatePersonaData: (state, actions) => {
      return { ...state, personasData: { ...actions.payload } };
    },
    updateCategoriesData: (state, actions) => {
      return { ...state, categoriesData: { ...actions.payload } };
    },
    updateWorldData: (state, actions) => {
      return { ...state, worldData: { ...actions.payload } };
    },
    updateCountriesData: (state, actions) => {
      return { ...state, countriesData: { ...actions.payload } };
    },
    updateIsLoading: (state) => {
      return { ...state, isLoading: !state.isLoading };
    },
    updateViewsVisitorsData: (state, actions) => {
      return { ...state, viewsVisitorsData: { ...actions.payload } };
    },
    updateCitiesData: (state, actions) => {
      return { ...state, citiesData: { ...actions.payload } };
    },
    updateAgeData: (state, actions) => {
      return { ...state, ageData: { ...actions.payload } };
    },
    updateGenderData: (state, actions) => {
      return { ...state, genderData: { ...actions.payload } };
    },
    updateTotalSpendData: (state, actions) => {
      return { ...state, totalSpendData: { ...actions.payload } };
    },
    updateVisitorsLineData: (state, actions) => {
      return { ...state, visitorsLineData: { ...actions.payload } };
    },
    updateSelectedPersonaFilterType: (state, action) => {
      return { ...state, selectedPersonaFilterType: action.payload };
    },
    updateRoomsData: (state, actions) => {
      return { ...state, roomsData: { ...actions.payload } };
    },
    updatePageUrlData: (state, actions) => {
      return { ...state, pageUrlData: { ...actions.payload } };
    },
    updateSourceData: (state, actions) => {
      return { ...state, sourceData: { ...actions.payload } };
    },
    updateMediumData: (state, actions) => {
      return { ...state, mediumData: { ...actions.payload } };
    },
    updateCampaignData: (state, actions) => {
      return { ...state, campaignData: { ...actions.payload } };
    },
    updateTermData: (state, actions) => {
      return { ...state, termData: { ...actions.payload } };
    },
    updateContentData: (state, actions) => {
      return { ...state, contentData: { ...actions.payload } };
    },
    updateHeatmap1Data: (state, actions) => {
      return { ...state, heatmap1Data: [...actions.payload] };
    },
    updateHeatmap2Data: (state, actions) => {
      return { ...state, heatmap2Data: [...actions.payload] };
    },
    updateHeatmap3Data: (state, actions) => {
      return { ...state, heatmap3Data: [...actions.payload] };
    },
    updateSelectedFilterTypeForHeatmap: (state, actions) => {
      return { ...state, selectedFilterTypeForHeatmap: actions.payload };
    },
    updateKeywordsData: (state, actions) => {
      return { ...state, keywordsData: { ...actions.payload } };
    },
    updateLongTailKeyWordsData: (state, actions) => {
      return { ...state, longTailKeyWordsData: { ...actions.payload } };
    },
    updatePagesAndKewordsFilteredData: (state, actions) => {
      return { ...state, pagesAndKewordsFilteredData: [...actions.payload] };
    },
    updateFetchFilterData: (state, actions) => {
      return { ...state, fetchFilterData: actions.payload };
    }
  },
  extraReducers: {
    [getPersonasData.pending]: (state) => {
      if (state.fetchFilterData) return { ...state, barGraphLoading: true };
      return { ...state, isLoading: true };
    },
    [getPersonasData.fulfilled]: (state, action) => {
      if (state.fetchFilterData)
        return {
          ...state,
          barGraphLoading: false,
          pmsToggleActivation: action.payload.pmsToggleActivation,
          allData: modifyData([...action.payload.result]),
          companyType: action.payload.companyType
        };
      return {
        ...state,
        isLoading: false,
        pmsToggleActivation: action.payload.pmsToggleActivation,
        allData: modifyData([...action.payload.result]),
        companyType: action.payload.companyType
      };
    },
    [getPersonasData.rejected]: (state) => {
      if (state.fetchFilterData) return { ...state, barGraphLoading: true };
      return { ...state, isLoading: false };
    },
    [getAnalyticsPages.pending]: (state) => {
      return { ...state, pagesAndKewords: { isLoading: true } };
    },
    [getAnalyticsPages.fulfilled]: (state, action) => {
      return {
        ...state,
        pagesAndKewords: { isLoading: false, data: [...action.payload[0]] }
      };
    },
    [getAnalyticsPages.rejected]: (state) => {
      return { ...state, pagesAndKewords: { isLoading: false } };
    }
  }
});

export const {
  updateFilteredData,
  updatePersonaData,
  updateCategoriesData,
  updateWorldData,
  updateCountriesData,
  updateIsLoading,
  updateViewsVisitorsData,
  updateCitiesData,
  updateAgeData,
  updateGenderData,
  updateTotalSpendData,
  updateVisitorsLineData,
  updateSelectedPersonaFilterType,
  updateRoomsData,
  updatePageUrlData,
  updateSourceData,
  updateMediumData,
  updateCampaignData,
  updateTermData,
  updateContentData,
  updateHeatmap1Data,
  updateHeatmap2Data,
  updateHeatmap3Data,
  updateSelectedFilterTypeForHeatmap,
  updateKeywordsData,
  updateLongTailKeyWordsData,
  updatePagesAndKewordsFilteredData,
  updateFetchFilterData
} = personasDataSlice.actions;

export default { personasDataSlice };
