import PopUpWindow from "./components/PopUpWindow";

export default function index({
  children,
  className,
  handlePagination,
  pageNumber,
  numberOfPages
}) {
  return (
    <PopUpWindow
      className={className}
      handlePagination={handlePagination}
      pageNumber={pageNumber}
      numberOfPages={numberOfPages}
    >
      {children}
    </PopUpWindow>
  );
}
