/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import AddBarGraph from "../../../../components/ChartComponents/GeneralBarGraph";

export default function CategoriesBarGraph({
  categoriesData,
  commonProps,
  t,
  loading
}) {
  return (
    <AddBarGraph
      loading={loading}
      title={t("personaDashboard.categories")}
      labels={categoriesData?.labels}
      numBars={
        categoriesData?.labels?.length < 6 ? categoriesData?.labels?.length : 6
      }
      data={{
        tooltip: t("analyticsPage.visitors"),
        values: categoriesData?.total
      }}
      columnData={[
        {
          name: t("analyticsPage.visitors"),
          diff: categoriesData?.total,
          number: true
        }
      ]}
      {...commonProps}
    />
  );
}

const commonPropsShape = PropTypes.shape({
  dashboard: PropTypes.string,
  isCompare: PropTypes.bool,
  filterValues: PropTypes.oneOfType([PropTypes.shape({})])
});

CategoriesBarGraph.propTypes = {
  categoriesData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    total: PropTypes.arrayOf(PropTypes.number)
  }),
  commonProps: commonPropsShape.isRequired,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool
};
