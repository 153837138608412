/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MultiBarChartComponent from "./components/MultiBarChartComponent";
import StackedChartComponent from "./components/StackedChartComponent";
import AgeBarGraph from "./components/AgeBarGraph";
import SpendBarGraph from "./components/SpendBarGraph";
import CategoriesBarGraph from "./components/CategoriesBarGraph";
import RoomsBarGraph from "./components/RoomsBarGraph";
import CitiesBarGraph from "./components/CitiesBarGraph";
import CountriesBarGraph from "./components/CountriesBarGraph";
import PageUrlBarGraph from "./components/PageUrlBarGraph";
import WorldMap from "../../../components/ChartComponents/WorldMap";
import LineChart from "../../DashBoard/DashBoardLandingPage/LineChart";
import DonutChart from "../../../components/ChartComponents/DonutChart";
import NoDataFound from "../../Placeholders/NoDataFound";
import PersonaLabel from "../../../components/PersonaLabel";
import Content from "./components/Content";
import SourceBarGraph from "./components/SourceBarGraph";
import MediumBarGraph from "./components/MediumBarGraph";
import CampaignBarGraph from "./components/CampaignBarGraph";
import { findPersonaDescriptionDashboard } from "../../../utils/findPersonaDescription";
import TermBarGraph from "./components/TermBarGraph";
import ContentBarGraph from "./components/ContentBarGraph";
import CollectingData from "../../../assets/icons/collectingData.svg";
import HeatMapWithLable from "../../../components/HeatMapWithLabels";
import AddBarGraph from "../../../components/ChartComponents/GeneralBarGraph";

export default function Index({
  dashboard,
  isCompare,
  selectedPersonaFilters,
  isToggled,
  updateSelectedPersonasFilters,
  pageCount,
  pageNumber,
  handlePagination,
  content,
  contentLoading
}) {
  const {
    allData,
    personasData,
    countriesData,
    worldData,
    viewsVisitorsData,
    categoriesData,
    ageData,
    genderData,
    citiesData,
    totalSpendData,
    visitorsLineData,
    roomsData,
    pageUrlData,
    sourceData,
    mediumData,
    campaignData,
    termData,
    contentData,
    heatmap1Data,
    heatmap2Data,
    heatmap3Data,
    selectedFilterTypeForHeatmap,
    companyType,
    keywordsData,
    longTailKeyWordsData,
    pagesAndKewords,
    barGraphLoading
  } = useSelector((state) => state.PersonasData);

  const { t } = useTranslation();
  const [limitedFilteredData, setLimitedFilteredData] = useState([]);

  useEffect(() => {
    setLimitedFilteredData(pageUrlData);
  }, [pageUrlData]);

  const handleSearch = (searchTerm) => {
    if (!searchTerm || searchTerm.length <= 2) {
      setLimitedFilteredData(pageUrlData);
      return;
    }

    const query = searchTerm?.toLowerCase();
    const {
      bounce,
      // dataset,
      labels,
      // links,
      preSales,
      revenue,
      sales,
      views,
      visitors
    } = pageUrlData;
    const filteredIndices = labels
      .map((label, index) => {
        if (label.toLowerCase().includes(query)) {
          return index;
        }
        return -1;
      })
      .filter((index) => index !== -1);
    if (filteredIndices?.length === 0) {
      setLimitedFilteredData(pageUrlData);
      return;
    }
    const filteredData = {
      bounce: filteredIndices.map((index) => bounce[index]),
      // dataset: filteredIndices.map((index) => dataset[index]),
      labels: filteredIndices.map((index) => labels[index]),
      // links: filteredIndices.map((index) => links[index]),
      preSales: filteredIndices.map((index) => preSales[index]),
      revenue: filteredIndices.map((index) => revenue[index]),
      sales: filteredIndices.map((index) => sales[index]),
      views: filteredIndices.map((index) => views[index]),
      visitors: filteredIndices.map((index) => visitors[index])
    };

    setLimitedFilteredData(filteredData);
  };

  const commonProps = {
    dashboard,
    isCompare,
    filterValues: selectedPersonaFilters
  };

  const personaDescription = findPersonaDescriptionDashboard(
    allData,
    commonProps.filterValues,
    t
  );

  if (allData.length === 0) {
    return <NoDataFound />;
  }

  return (
    <>
      {heatmap2Data?.length && heatmap3Data?.length && (
        <HeatMapWithLable
          heatmap1Data={heatmap1Data}
          heatmap2Data={heatmap2Data}
          heatmap3Data={heatmap3Data}
          isToggled={isToggled}
          dashboard={dashboard}
          selectedFilters={selectedPersonaFilters}
          updateFilters={updateSelectedPersonasFilters}
          filterType={selectedFilterTypeForHeatmap}
          companyType={companyType}
        />
      )}
      {isToggled && (
        <MultiBarChartComponent
          visitorsLineData={visitorsLineData}
          commonProps={commonProps}
          t={t}
        />
      )}

      <div className="position-relative">
        {personasData?.labels?.length === 1 &&
          personasData?.labels?.includes(t("common.other")) && (
            <div className="illustration-background">
              <img
                src={CollectingData}
                alt="collecting-data-Icon"
                className="illustration-icon"
              />
              <span className="illustration-heading">
                {t("common.collectingYourData")}
              </span>
            </div>
          )}
        <div className="flex-column gap-10">
          <StackedChartComponent
            personasData={personasData}
            updateSelectedPersonasFilters={updateSelectedPersonasFilters}
            selectedPersonaFilters={selectedPersonaFilters}
            t={t}
          />
          {/* <PersonaLabel personaDescription={personaDescription} /> */}
        </div>
      </div>

      {isToggled && (
        <div className="grid half-column-grid">
          <AgeBarGraph
            ageData={ageData}
            commonProps={commonProps}
            t={t}
            loading={barGraphLoading}
          />
          <DonutChart
            title={t("personaDashboard.gender")}
            labels={genderData?.labels}
            dataSet={genderData?.visitors}
            selectedFilters={selectedPersonaFilters}
            updateFilters={updateSelectedPersonasFilters}
          />
        </div>
      )}

      {isToggled && (
        <div className="grid half-column-grid">
          <SpendBarGraph
            totalSpendData={totalSpendData}
            commonProps={commonProps}
            t={t}
            loading={barGraphLoading}
          />

          <RoomsBarGraph
            roomsData={roomsData}
            commonProps={commonProps}
            t={t}
            loading={barGraphLoading}
          />
        </div>
      )}

      <div
        className={
          isToggled
            ? "grid half-column-grid general-bar-graph-height"
            : "grid minimum-height-for-pages"
        }
      >
        {isToggled && (
          <CitiesBarGraph
            citiesData={citiesData}
            commonProps={commonProps}
            t={t}
            loading={barGraphLoading}
          />
        )}
        <CountriesBarGraph
          countriesData={countriesData}
          commonProps={commonProps}
          t={t}
          loading={barGraphLoading}
        />
      </div>

      {!isToggled && (
        <div className="grid">
          <WorldMap
            data={worldData?.dataset}
            labels={worldData.labels}
            title={t("analyticsPage.worldTitle")}
            selectedFilters={selectedPersonaFilters}
            updateFilters={updateSelectedPersonasFilters}
          />
        </div>
      )}

      <div className="grid">
        <LineChart
          views={viewsVisitorsData?.views}
          visitors={viewsVisitorsData?.visitors}
          labels={viewsVisitorsData?.labels}
          title={t("analyticsPage.visitorsPageViewsTitle")}
        />
      </div>

      <div className="grid general-bar-graph-height">
        <CategoriesBarGraph
          categoriesData={categoriesData}
          commonProps={commonProps}
          t={t}
          loading={barGraphLoading}
        />
      </div>
      <div className="grid general-bar-graph-height">
        <AddBarGraph
          loading={pagesAndKewords?.isLoading}
          labels={keywordsData?.labels}
          title={t("analyticsPage.keywords")}
          numBars={
            keywordsData?.labels?.length < 6 ? keywordsData?.labels?.length : 6
          }
          data={{
            tooltip: t("analyticsPage.visitors"),
            values: keywordsData?.visitors
          }}
          columnData={[
            {
              name: t("analyticsPage.visitors"),
              diff: keywordsData?.visitors,
              number: true
            }
          ]}
          {...commonProps}
        />
      </div>
      <div className="grid general-bar-graph-height">
        <AddBarGraph
          loading={pagesAndKewords?.isLoading}
          labels={longTailKeyWordsData?.labels}
          title={t("analyticsPage.longTailKeywords")}
          numBars={
            longTailKeyWordsData?.labels?.length < 6
              ? longTailKeyWordsData?.labels?.length
              : 6
          }
          data={{
            tooltip: t("analyticsPage.visitors"),
            values: longTailKeyWordsData?.visitors
          }}
          columnData={[
            {
              name: t("analyticsPage.visitors"),
              diff: longTailKeyWordsData?.visitors,
              number: true
            }
          ]}
          {...commonProps}
        />
      </div>
      {selectedPersonaFilters[t("analyticsPage.longTailKeywords")] && (
        <Content
          pageCount={pageCount}
          pageNumber={pageNumber}
          handlePagination={handlePagination}
          content={content}
          loading={contentLoading}
        />
      )}
      <div className="minimum-height-for-pages">
        <PageUrlBarGraph
          pageUrlData={pageUrlData}
          commonProps={commonProps}
          t={t}
          loading={pagesAndKewords?.isLoading}
          limitedFilteredData={limitedFilteredData}
          setLimitedFilteredData={setLimitedFilteredData}
          handleSearch={handleSearch}
          search={true}
        />
      </div>
      <div className="grid grid-template">
        <div className="general-bar-graph-height">
          <SourceBarGraph
            sourceData={sourceData}
            commonProps={commonProps}
            t={t}
            loading={barGraphLoading}
          />
        </div>
        <div className="general-bar-graph-height">
          <MediumBarGraph
            mediumData={mediumData}
            commonProps={commonProps}
            t={t}
            loading={barGraphLoading}
          />
        </div>
        <div className="general-bar-graph-height">
          <CampaignBarGraph
            campaignData={campaignData}
            commonProps={commonProps}
            t={t}
            loading={barGraphLoading}
          />
        </div>
      </div>

      <div className="grid half-column-grid">
        <div className="general-bar-graph-height">
          <TermBarGraph
            termData={termData}
            commonProps={commonProps}
            t={t}
            loading={barGraphLoading}
          />
        </div>
        <div className="general-bar-graph-height">
          <ContentBarGraph
            contentData={contentData}
            commonProps={commonProps}
            t={t}
            loading={barGraphLoading}
          />
        </div>
      </div>
    </>
  );
}

Index.propTypes = {
  dashboard: PropTypes.string,
  isCompare: PropTypes.bool,
  selectedPersonaFilters: PropTypes.oneOfType([PropTypes.shape({})]),
  isToggled: PropTypes.bool,
  updateSelectedPersonasFilters: PropTypes.func,
  pageCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  handlePagination: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.array]).isRequired,
  contentLoading: PropTypes.bool
};
