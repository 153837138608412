/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import AddBarGraph from "../../../../components/ChartComponents/GeneralBarGraph";

export default function RoomsBarGraph({ roomsData, commonProps, t, loading }) {
  return (
    <AddBarGraph
      loading={loading}
      title={t("PMSPage.roomsTitle")}
      // subtitle={t("PMSPage.roomType")}
      labels={roomsData?.labels}
      data={{
        tooltip: t("PMSPage.requests"),
        values: roomsData?.requests
      }}
      numBars={roomsData?.labels?.length < 6 ? roomsData?.labels?.length : 6}
      columnData={[
        {
          id: 1,
          name: t("PMSPage.requests"),
          diff: roomsData?.requests,
          number: true
        },
        {
          id: 2,
          name: t("PMSPage.roomReq"),
          diff: roomsData?.roomReq,
          number: true
        }
      ]}
      {...commonProps}
    />
  );
}

const commonPropsShape = PropTypes.shape({
  dashboard: PropTypes.string,
  isCompare: PropTypes.bool,
  filterValues: PropTypes.oneOfType([PropTypes.shape({})])
});

RoomsBarGraph.propTypes = {
  roomsData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    requests: PropTypes.arrayOf(PropTypes.number),
    roomReq: PropTypes.arrayOf(PropTypes.number)
  }),
  commonProps: commonPropsShape.isRequired,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool
};
