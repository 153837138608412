import React from "react";
import PropTypes from "prop-types";
import Content from "./components/Content";

export default function Index({
  pageCount,
  pageNumber,
  handlePagination,
  content,
  loading
}) {
  return (
    <Content
      content={content}
      pageCount={pageCount}
      pageNumber={pageNumber}
      handlePagination={handlePagination}
      loading={loading}
    />
  );
}

Index.propTypes = {
  pageCount: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  handlePagination: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([PropTypes.array]).isRequired,
  loading: PropTypes.bool.isRequired
};
