/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import AddBarGraph from "../../../../components/ChartComponents/GeneralBarGraph";

export default function CitiesBarGraph({
  citiesData,
  commonProps,
  t,
  loading
}) {
  return (
    <AddBarGraph
      loading={loading}
      labels={citiesData.labels}
      title={t("personaDashboard.cities")}
      numBars={citiesData?.labels?.length < 6 ? citiesData?.labels?.length : 6}
      data={{
        tooltip: t("analyticsPage.visitors"),
        values: citiesData?.visitors
      }}
      columnData={[
        {
          name: t("analyticsPage.visitors"),
          diff: citiesData?.visitors,
          number: true
        }
      ]}
      {...commonProps}
    />
  );
}

const commonPropsShape = PropTypes.shape({
  dashboard: PropTypes.string,
  isCompare: PropTypes.bool,
  filterValues: PropTypes.oneOfType([PropTypes.shape({})])
});

CitiesBarGraph.propTypes = {
  citiesData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    visitors: PropTypes.arrayOf(PropTypes.number)
  }),
  commonProps: commonPropsShape.isRequired,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool
};
