/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import AddBarGraph from "../../../../components/ChartComponents/GeneralBarGraph";

export default function PageUrlBarGraph({
  pageUrlData,
  commonProps,
  t,
  loading,
  limitedFilteredData,
  setLimitedFilteredData,
  handleSearch,
  search
}) {
  return (
    <AddBarGraph
      loading={loading}
      data={{
        tooltip: t("analyticsPage.visitors"),
        values: limitedFilteredData?.visitors
      }}
      labels={limitedFilteredData?.labels}
      title={t("analyticsPage.pagesTitle")}
      subtitle={t("analyticsPage.pagesTitle")}
      limitedFilteredData={limitedFilteredData}
      setLimitedFilteredData={setLimitedFilteredData}
      pageUrlDataNew={pageUrlData}
      search={search}
      handleSearch={handleSearch}
      numBars={
        limitedFilteredData?.labels?.length < 25
          ? limitedFilteredData?.labels?.length
          : 25
      }
      columnData={[
        {
          id: 1,
          name: t("analyticsPage.visitors"),
          diff: limitedFilteredData?.visitors,
          number: true
        },
        {
          id: 2,
          name: t("analyticsPage.views"),
          diff: limitedFilteredData?.views,
          number: true
        },
        {
          id: 3,
          name: t("analyticsPage.bounce"),
          diff: limitedFilteredData?.bounce,
          percent: true
        },
        {
          id: 4,
          name: t("analyticsPage.preSalesEvent"),
          diff: limitedFilteredData?.preSales,
          number: true
        },
        {
          id: 5,
          name: t("analyticsPage.salesEvent"),
          diff: limitedFilteredData?.sales,
          number: true
        },
        {
          id: 6,
          name: t("analyticsPage.revenue"),
          diff: limitedFilteredData?.revenue,
          euro: true
        }
      ]}
      {...commonProps}
    />
  );
}

const commonPropsShape = PropTypes.shape({
  dashboard: PropTypes.string,
  isCompare: PropTypes.bool,
  filterValues: PropTypes.oneOfType([PropTypes.shape({})])
});

PageUrlBarGraph.propTypes = {
  pageUrlData: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    visitors: PropTypes.arrayOf(PropTypes.number),
    views: PropTypes.arrayOf(PropTypes.number),
    preSales: PropTypes.arrayOf(PropTypes.number),
    sales: PropTypes.arrayOf(PropTypes.number),
    revenue: PropTypes.arrayOf(PropTypes.number)
  }),
  commonProps: commonPropsShape.isRequired,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  limitedFilteredData: PropTypes.oneOfType([PropTypes.array]).isRequired,
  handleSearch: PropTypes.func.isRequired,
  search: PropTypes.bool,
  setLimitedFilteredData: PropTypes.func
};
