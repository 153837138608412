/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import LocalStorage from "../../utils/LocalStorgae";
import HeatMapComponent from "./components/HeatMapComponent";
import ChartHeader from "../ChartHeader/ChartHeader";
import prev from "./assets/icons/chevron-back.svg";
import next from "./assets/icons/chevron-forward.svg";
import { getHeatMapData } from "./api/heatmapAPI";
import themeColors from "../../assets/stylesheets/_var.scss";
import "./assets/stylesheets/heatmap.scss";
import { updateSelectedHeatMap } from "../../store/slices/dataPMSSlice";
import storage from "../../utils/storage";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
`;

export default function Index({ title, loading, selectedHeatMap }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  if (loading) console.log("");
  const { websiteID, createdBy } = useSelector((state) => state.generalData);
  const legendColors = {
    0: themeColors.fourthText,
    29: themeColors.primaryColor4,
    59: themeColors.primaryColor3,
    89: themeColors.primaryColorShade2,
    100: themeColors.primaryColor
  };
  const language = LocalStorage.getItem("selectedLanguage");
  const [heatmapValue, setValue] = useState([]);
  const stored = selectedHeatMap?.split("-");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(stored?.[1] ?? null);
  const [loader, setLoader] = useState(false);
  const fetchData = async () => {
    try {
      setLoader(true);
      const res = await getHeatMapData(
        {
          website_id: websiteID,
          year: selectedYear,
          customer_id: createdBy
        },
        t
      );
      setValue(res.data.result);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const moveNextYear = () => {
    setSelectedYear(selectedYear + 1);
  };

  const movePrevYear = () => {
    setSelectedYear(selectedYear - 1);
  };

  useEffect(() => {
    if (stored?.[1] !== selectedMonth) {
      dispatch(
        updateSelectedHeatMap(
          selectedMonth === null ? null : `${selectedYear}-${selectedMonth}`
        )
      );

      storage.setItem(
        "heatmap",
        selectedMonth === null ? null : `${selectedYear}-${selectedMonth}`
      );
    }
  }, [selectedMonth]);

  useEffect(() => {
    fetchData();
  }, [selectedYear]);

  const englishMonthLabels = [
    { id: "01", month: "Jan" },
    { id: "02", month: "Feb" },
    { id: "03", month: "Mar" },
    { id: "04", month: "Apr" },
    { id: "05", month: "May" },
    { id: "06", month: "Jun" },
    { id: "07", month: "Jul" },
    { id: "08", month: "Aug" },
    { id: "09", month: "Sep" },
    { id: "10", month: "Oct" },
    { id: "11", month: "Nov" },
    { id: "12", month: "Dec" }
  ];

  const italianMonthLabels = [
    { id: "01", month: "Gen" },
    { id: "02", month: "Feb" },
    { id: "03", month: "Mar" },
    { id: "04", month: "Apr" },
    { id: "05", month: "Mag" },
    { id: "06", month: "Giu" },
    { id: "07", month: "Lug" },
    { id: "08", month: "Ago" },
    { id: "09", month: "Set" },
    { id: "10", month: "Ott" },
    { id: "11", month: "Nov" },
    { id: "12", month: "Dic" }
  ];

  const germanMonthLabels = [
    { id: "01", month: "Jan" },
    { id: "02", month: "Feb" },
    { id: "03", month: "Mär" },
    { id: "04", month: "Apr" },
    { id: "05", month: "Mai" },
    { id: "06", month: "Jun" },
    { id: "07", month: "Jul" },
    { id: "08", month: "Aug" },
    { id: "09", month: "Sep" },
    { id: "10", month: "Okt" },
    { id: "11", month: "Nov" },
    { id: "12", month: "Dez" }
  ];

  const monthLabels =
    language === "en"
      ? englishMonthLabels
      : language === "it"
      ? italianMonthLabels
      : germanMonthLabels;
  return (
    <div className="heatmap background-box">
      <ChartHeader
        title={title}
        heatmap={legendColors}
        onReload={() => {
          setSelectedYear(new Date().getFullYear());
          setSelectedMonth(null);
        }}
        bookingPercentage={heatmapValue[0]?.roomNumber !== 0}
        showRefreshIcon={
          selectedMonth !== null || selectedYear !== new Date().getFullYear()
        }
      />
      {loader && (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}

      <div className="flex-end navigation-buttons">
        <button
          type="button"
          onClick={movePrevYear}
          style={{ cursor: "pointer" }}
        >
          <img src={prev} alt="chevron-back" />
        </button>
        <button
          type="button"
          onClick={moveNextYear}
          style={{ cursor: "pointer" }}
        >
          <img src={next} alt="chevron-forward" />
        </button>
      </div>

      <div className="heatmap-labels">
        {" "}
        <div className="month-labels">
          {monthLabels.map((label) => (
            <button
              type="button"
              onClick={() => {
                if (selectedMonth === label.id) setSelectedMonth(null);
                else setSelectedMonth(label.id);
              }}
              className={selectedMonth === label.id ? "button-color" : ""}
            >
              {label.month} {selectedYear}
            </button>
          ))}
        </div>
        <HeatMapComponent
          month={selectedMonth}
          values={heatmapValue}
          year={selectedYear}
          legendColors={legendColors}
        />
      </div>
    </div>
  );
}

Index.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  selectedHeatMap: PropTypes.string
};
