const formatElapsedTime = (timestamp, t) => {
  const date = new Date(timestamp);
  const now = new Date();

  const elapsedMilliseconds = now - date;
  const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);

  if (elapsedSeconds < 60) {
    return `${elapsedSeconds} ${elapsedSeconds === 1 ? "sec" : "secs"} ago`;
  }

  if (elapsedSeconds < 3600) {
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    return `${elapsedMinutes} ${elapsedMinutes === 1 ? "min" : "mins"} ago`;
  }

  const elapsedHours = Math.floor(elapsedSeconds / 3600);
  if (elapsedHours >= 24) {
    const elapsedDays = Math.floor(elapsedHours / 24);
    return `${elapsedDays} day${elapsedDays > 1 ? "s ago" : ""}`;
  }

  return `${elapsedHours} ${
    elapsedHours === 1 ? "hr ago" : t("notification.hrsago")
  }`;
};

export default formatElapsedTime;
